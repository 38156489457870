<template>
  <div>
    <SpinnerLoader v-if="mmrKazakhstanStatus !== 'success'" :loading="mmrKazakhstanStatus" />
    <template v-else-if="mmrKazakhstanStatus === 'success' && mmrKazakhstan && mmrKazakhstan.totals">
      <virtual-scroll :items="mmrKazakhstan.items" :item-height="28.6" class="vscroll table-responsive mb-0 mmr-kazakhstan">
        <template slot-scope="{ items }">
          <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
            <thead>
              <tr>
                <th>
                  {{ $t('table.externalId') }}
                </th>
                <th>
                  <div class="resizable channel_name">{{ $t('channelModal.channel') }}</div>
                </th>
                <th>
                  <div class="resizable advertiser">{{ $t('table.advertiser') }}</div>
                </th>
                <th>
                  <div class="resizable brand">{{ $t('reports.product') }}</div>
                </th>
                <th>
                  <div class="resizable brand_group">{{ $t('reports.productCategory') }}</div>
                </th>
                <th>
                  <div class="resizable agency">{{ $t('table.agency') }}</div>
                </th>
                <th>
                  <div class="resizable type">{{ $t('table.commercialType') }}</div>
                </th>
                <th>{{ $t('channelModal.prime') }}/<br />{{ $t('channelModal.offPrime') }}</th>
                <th>{{ $t('table.date') }}</th>
                <th class="text-right">{{ $t('reports.cost') }}</th>
                <th class="text-right">{{ $t('reports.cost') }} <br />(-12%)</th>
                <th class="text-right">{{ $t('table.duration') }} <br />({{ $t('reports.min') }})</th>
                <th class="text-right">{{ $t('reports.baseTA') }} <br />(wGRP)</th>
                <!--  ALL TA wgrp cols here -->
                <th v-for="(ta, index) in mmrKazakhstan.totals.wgrp_target_audience" :key="index" class="text-right">{{ index }}<br />(wGRP)</th>
                <th>
                  <div class="resizable desc">{{ $t('reports.description') }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="`${item.brand_name}_${item.brand_group_name}_${item.agency_name}_${item.advertiser_name}_${index}`">
                <td name="external_id">{{ item.external_id }}</td>
                <td name="channel_name">{{ item.channel_name }}</td>
                <td name="advertiser">{{ item.advertiser_name }}</td>
                <td name="brand">{{ item.brand_name }}</td>
                <td name="brand_group">{{ item.brand_group_name }}</td>
                <td name="agency">{{ item.agency_name }}</td>
                <td name="type">{{ item.commercial_type_name }}</td>
                <td>{{ item.is_prime_time ? $t('channelModal.prime') : $t('channelModal.offPrime') }}</td>
                <td>{{ new Date(item.date).toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' }) }}</td>
                <td class="text-right">{{ item.price_plan_fact | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.price_plan_fact_wo_vat | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.minutes_duration | toFixedAndSpace }}</td>
                <td class="text-right">{{ item.wgrp_base_target_audience | toFixedAndSpace }}</td>
                <!--  ALL TA wgrp cols here -->
                <td v-for="(taRating, taName) in mmrKazakhstan.totals.wgrp_target_audience" :key="taName" class="text-right">
                  <template v-if="item.wgrp_target_audience[taName] !== undefined">
                    {{ item.wgrp_target_audience[taName] | toFixedAndSpace }}
                  </template>
                </td>
                <td name="desc">{{ item.project_name }}</td>
              </tr>
            </tbody>
            <tfoot class="font-weight-bold">
              <tr>
                <th colspan="9">{{ $t('booking.total') }}</th>
                <th class="text-right">{{ mmrKazakhstan.totals.price_plan_fact | toFixedAndSpace }}</th>
                <th class="text-right">{{ mmrKazakhstan.totals.price_plan_fact_wo_vat | toFixedAndSpace }}</th>
                <th class="text-right">{{ mmrKazakhstan.totals.minutes_duration | toFixedAndSpace }}</th>
                <th class="text-right">{{ mmrKazakhstan.totals.wgrp_base_target_audience | toFixedAndSpace }}</th>
                <th v-for="(ta, index) in mmrKazakhstan.totals.wgrp_target_audience" :key="index" class="text-right">{{ ta | toFixedAndSpace }}</th>
                <th></th>
              </tr>
            </tfoot>
          </table>
        </template>
      </virtual-scroll>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import VirtualScroll from '@/components/VirtualScroll';
import convertDate from '@/filters/convertDate';

export default {
  name: 'CostTable',
  components: { SpinnerLoader, VirtualScroll },
  filters: { toFixedAndSpace, convertDate },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      mmrKazakhstan: 'getReportMMRKazakhstan',
      mmrKazakhstanStatus: 'getReportMMRKazakhstanStatus',
      locale: 'isLocale',
    }),
  },
  destroyed() {
    this.$store.commit('clearReportMMRKazakhstan');
  },
  methods: {},
};
</script>
<style lang="sass">
.reports-page div.table-responsive.mmr-kazakhstan
  height: calc(100vh - 210px)

.reports-page .mmr-kazakhstan table.table
  div.resizable
    &.channel_name,
    &.type,
    &.agency,
    &.advertiser,
    &.brand,
    &.brand_group
      min-width: 80px
    &.desc
      min-width: 120px

  td[name="channel_name"],
  td[name="type"],
  td[name="agency"],
  td[name="advertiser"],
  td[name="brand"],
  td[name="brand_group"]
    max-width: 80px
  td[name="desc"]
    max-width: 120px

@media (min-width: 1630px)
  .reports-page div.table-responsive.category-summary-extended
    height: calc(100vh - 154px)
</style>
